import { defined } from './lib/types/assert';
import pkg from '../package.json';

export const APP_DOMAIN = 'amplify-video.com';
export const APP_URL = `https://${APP_DOMAIN}`;
export const APP_VERSION = pkg.version || '1.0.0';
export const GOOGLE_ANALYTICS = 'G-06NP7KB191';
export const IS_PRODUCTION = process.env.NODE_ENV === 'production';
export const SENTRY_DNS =
  'https://201092ce202b73a046f126f486f5d56d@o4506852645666816.ingest.sentry.io/4506852676534273';
export const AMPLITUDE_API_KEY = defined(process.env.NEXT_PUBLIC_AMPLITUDE_API_KEY, 'NEXT_PUBLIC_AMPLITUDE_API_KEY');
